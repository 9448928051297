import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    IconButton,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tooltip,
    Tr,
    UnorderedList,
    Box,
    ListItem,
} from '@chakra-ui/react';

import {
    Alert,
    AlertDescription,
    AlertIcon,
    Heading,
    Input,
    Text,
} from 'components/v4';
import NumberFormat from 'react-number-format';
import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import walkthroughIds from '../walkthroughIds';

/*
 *
 */
const ExistingNumbersTable = ({ setState }) => {
    const dispatch = useDispatch();
    const { state: modalState, stateError: modalStateError } = useSelector(
        (state) => {
            return { ...state.modal };
        },
    );
    const {
        numbersPortFrom,
        numbersPortTo,
        numbersPortAddRow,
        numbersPortClearRow,
    } = walkthroughIds.bringExistingNumber;

    const { darkMode } = useSelector((state) => state.settings);
    const addButtonRef = useRef(null);
    const clearButtonRef = useRef(null);

    const getTableState = () => {
        return modalState?.aggregateTable;
    };

    const setTableStateError = (payload) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE_ERROR',
            payload: { ...modalStateError, ...payload },
        });
    };

    const setTableState = (payload) => {
        setState({ aggregateTable: payload });
    };

    const [focusedRow, setFocusedRow] = useState(-1);
    const fromInputRef = useRef(null);
    const handleNextFocus = (index, id) => {
        switch (index) {
            case 0: {
                setFocusedRow(id);
                fromInputRef.current.focus();
                break;
            }
            case 1: {
                setFocusedRow(id + 1);
                addButtonRef.current.focus();
                addButtonRef.current.click();
                break;
            }
            case 2: {
                setFocusedRow(id + 1);
                break;
            }

            default: {
                setFocusedRow(-1);
                return null;
            }
        }
    };

    const handleAddButton = () => {
        const maxId = Math.max(
            0,
            ...modalState.aggregateTable.map((row) => row.id),
        ); // Get the maximum ID in the table
        const newRow = {
            id: maxId + 1, // Ensure unique ID for the new row
            from: '',
            to: '',
        };
        setTableState([...modalState.aggregateTable, newRow]); // Add the new row to the table
    };

    const handleClearButton = (index) => {
        const newTable = [...modalState.aggregateTable];
        newTable.splice(index, 1); // Remove the row at the specified index

        if (newTable.length === 0) {
            setTableState([{ id: 1, from: '', to: '' }]); // Reset to default values if table is empty
        } else {
            // Reassign IDs to ensure they are unique and in ascending order
            const updatedTable = newTable.map((row, idx) => ({
                ...row,
                id: idx + 1, // Assign new IDs starting from 1
            }));
            setTableState(updatedTable);
        }
    };

    const [duplicates, setDuplicates] = useState([]);
    const [invalidRanges, setInvalidRanges] = useState([]);
    const [overlaps, setOverlaps] = useState([]);
    const [hasError, setHasError] = useState(false);

    function findFromDuplicates(arr) {
        const fromValues = new Set();
        const duplicates = new Set();

        arr?.forEach((entry) => {
            const { from } = entry; // Only extract the "from" value

            // Skip empty, null, or undefined "from" values
            if (from && from.trim() !== '') {
                if (fromValues.has(from)) {
                    duplicates.add(entry); // Add to duplicates if "from" is already seen
                } else {
                    fromValues.add(from); // Otherwise, add "from" to seen values
                }
            }
        });

        return Array.from(duplicates);
    }

    function validateFromTo(arr) {
        const invalidEntries = [];

        arr?.forEach((entry) => {
            const { from, to } = entry; // Convert both values to numbers
            if (to !== '' && to?.length > 10 && to < from) {
                invalidEntries.push(entry); // Add to invalidEntries if to < from
            }
        });

        return invalidEntries;
    }

    function checkForOverlaps(arr) {
        const overlaps = []; // Array to store overlapping ranges

        arr?.forEach((entry, index) => {
            // Skip entries with empty 'from' or 'to' values
            if (!entry.from || !entry.to) {
                return; // Skip this iteration if 'from' or 'to' is empty
            }

            // Check for overlaps
            for (let j = index + 1; j < arr.length; j++) {
                const compareRange = arr[j];

                // Skip comparisons with empty 'from' or 'to' values
                if (!compareRange.from || !compareRange.to) {
                    continue; // Skip this comparison if 'from' or 'to' is empty
                }

                // Check if ranges overlap
                if (
                    (entry.from <= compareRange.to &&
                        entry.to >= compareRange.from) ||
                    (compareRange.from <= entry.to &&
                        compareRange.to >= entry.from)
                ) {
                    overlaps.push({ entry, compareRange });
                }
            }
        });

        return overlaps;
    }

    useEffect(() => {
        const invalidRanges = validateFromTo(modalState.aggregateTable);
        const overlaps =
            invalidRanges.length === 0
                ? checkForOverlaps(modalState.aggregateTable)
                : [];
        const duplicates =
            invalidRanges.length === 0 && overlaps.length === 0
                ? findFromDuplicates(modalState.aggregateTable)
                : [];

        // Prepare the error state updates in one go
        const newTableStateError = {
            invalidRanges: invalidRanges.length > 0,
            overlaps: overlaps.length > 0,
            duplicates: duplicates.length > 0,
        };

        // Update all error states at once
        setTableStateError(newTableStateError);

        // Determine whether there are any errors
        const hasError = Object.values(newTableStateError).some(
            (error) => error,
        );
        setHasError(hasError);

        // Set the individual state for invalid ranges, overlaps, and duplicates
        setInvalidRanges(invalidRanges);
        setOverlaps(overlaps);
        setDuplicates(duplicates);
    }, [modalState.aggregateTable]);

    return (
        <Box>
            <Text>Numbers to Port</Text>
            <Alert status="info" display="-webkit-box" m={'10px 0px'}>
                <AlertIcon />
                To quickly add multiple numbers to port, copy and paste a line
                separated list of number ranges into the{' '}
                <Text as="b" mr="3px">
                    From
                </Text>
                field below
            </Alert>

            {(invalidRanges?.length > 0 ||
                overlaps?.length > 0 ||
                duplicates.filter((number) => number.from !== '').length > 0 ||
                modalStateError?.numberlist) && (
                <Alert status="error" mb={4}>
                    <AlertIcon />
                    <AlertDescription>
                        <Box>
                            <Heading fontSize="1rem">Error! </Heading>
                            <UnorderedList>
                                {overlaps &&
                                    overlaps.map((obj, ind) => (
                                        <ListItem
                                            className="list-inside"
                                            key={
                                                ind
                                            }>{`Rows ${obj.entry.id} and ${obj.compareRange.id} has overlap number ranges`}</ListItem>
                                    ))}
                                {invalidRanges &&
                                    invalidRanges.map((obj) => (
                                        <ListItem
                                            className="list-inside"
                                            key={obj.id}>{`Row ${
                                            obj.id
                                        } has invalid number range: ${
                                            obj.from
                                        } - ${obj.to}`}</ListItem>
                                    ))}
                                {duplicates &&
                                    duplicates.map((obj) => (
                                        <ListItem
                                            className="list-inside"
                                            key={obj.id}>{`Row ${
                                            obj.id
                                        } has entered a duplicate number: ${
                                            obj.from
                                        }`}</ListItem>
                                    ))}
                                {modalStateError?.numberlist && (
                                    <ListItem className="list-inside">
                                        {modalStateError.numberlist}
                                    </ListItem>
                                )}
                            </UnorderedList>
                        </Box>
                    </AlertDescription>
                </Alert>
            )}

            <Box maxHeight={'300px'} overflowY="auto">
                <Table variant="unstyled" size="sm">
                    <Thead>
                        <Tr>
                            <Th>
                                <Text>ID</Text>
                            </Th>
                            <Th>
                                <Text>From</Text>
                            </Th>
                            <Th>
                                <Text>To</Text>
                            </Th>
                            <Th>
                                <Text>Action</Text>
                            </Th>
                        </Tr>
                    </Thead>

                    <Tbody>
                        {getTableState()?.map(({ from, to, id }, index) => {
                            return (
                                <Tr key={id + index}>
                                    {/* ID */}
                                    <Td>{id}</Td>
                                    {/* From */}
                                    <Td>
                                        <Input
                                            as={NumberFormat}
                                            isNumericString
                                            allowNegative={false}
                                            decimalScale={0}
                                            fixedDecimalScale={true}
                                            name="from"
                                            id="from"
                                            variant="flushed"
                                            value={from}
                                            getInputRef={(el) =>
                                                (fromInputRef.current = el)
                                            }
                                            ref={fromInputRef}
                                            _placeholder={{
                                                color: darkMode
                                                    ? '#6c757d'
                                                    : null,
                                            }}
                                            placeholder="Please enter from number in E164 format."
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault();
                                                    handleNextFocus(1, id);
                                                }
                                            }}
                                            isInvalid={Boolean(hasError)}
                                            color={
                                                hasError ? 'red.500' : 'inherit'
                                            }
                                            autoFocus={id === focusedRow}
                                            onPaste={(event) => {
                                                event.preventDefault(); // Prevent the default paste action

                                                // Get pasted data
                                                const pastedData =
                                                    event.clipboardData
                                                        .getData('text')
                                                        .trim();
                                                const newTable = [
                                                    ...getTableState(),
                                                ]; // Create a new copy of the table
                                                const currentRow =
                                                    newTable[index] || {}; // Get the current row being edited

                                                // Split the pasted data by new line, comma, space, or hyphen
                                                const splitEntries = pastedData
                                                    .split(/[\r?\n\s,]+/)
                                                    .map((entry) =>
                                                        entry.trim(),
                                                    )
                                                    .filter(Boolean); // Remove empty entries

                                                if (splitEntries.length > 0) {
                                                    // Handle single pasted value to override the current row
                                                    if (
                                                        splitEntries.length ===
                                                        1
                                                    ) {
                                                        const entry =
                                                            splitEntries[0];
                                                        const [from, to] =
                                                            entry.includes('-')
                                                                ? entry
                                                                      .split(
                                                                          '-',
                                                                      )
                                                                      .map(
                                                                          (
                                                                              num,
                                                                          ) =>
                                                                              num.trim(),
                                                                      )
                                                                : [
                                                                      entry,
                                                                      entry,
                                                                  ]; // If no hyphen, use the same value for 'from' and 'to'

                                                        // Override the current row's from and to values
                                                        newTable[index] = {
                                                            id:
                                                                currentRow.id ||
                                                                newTable.length +
                                                                    1, // Use existing ID or generate a new one
                                                            from:
                                                                from.length > 0
                                                                    ? from
                                                                    : '', // Ensure 'from' is valid
                                                            to:
                                                                currentRow?.to
                                                                    ?.length > 0
                                                                    ? currentRow?.to
                                                                    : to.length >
                                                                        0
                                                                      ? to
                                                                      : '', // Ensure 'to' is valid
                                                        };
                                                    } else {
                                                        // Handle multiple pasted entries
                                                        splitEntries.forEach(
                                                            (entry, idx) => {
                                                                const [
                                                                    from,
                                                                    to,
                                                                ] =
                                                                    entry.includes(
                                                                        '-',
                                                                    )
                                                                        ? entry
                                                                              .split(
                                                                                  '-',
                                                                              )
                                                                              .map(
                                                                                  (
                                                                                      num,
                                                                                  ) =>
                                                                                      num.trim(),
                                                                              )
                                                                        : [
                                                                              entry,
                                                                              entry,
                                                                          ]; // If no hyphen, use the same value for 'from' and 'to'

                                                                if (idx === 0) {
                                                                    // Update the current row if it's the first entry
                                                                    newTable[
                                                                        index
                                                                    ] = {
                                                                        id:
                                                                            currentRow.id ||
                                                                            newTable.length +
                                                                                1, // Use existing ID or generate a new one
                                                                        from:
                                                                            from.length >
                                                                            0
                                                                                ? from
                                                                                : '', // Ensure 'from' is valid
                                                                        to:
                                                                            to.length >
                                                                            0
                                                                                ? to
                                                                                : '', // Ensure 'to' is valid
                                                                    };
                                                                } else {
                                                                    // Generate a new unique ID for each additional pasted value
                                                                    const maxId =
                                                                        Math.max(
                                                                            0,
                                                                            ...newTable.map(
                                                                                (
                                                                                    item,
                                                                                ) =>
                                                                                    item.id,
                                                                            ),
                                                                        ); // Get the maximum ID in the table
                                                                    newTable.push(
                                                                        {
                                                                            id:
                                                                                maxId +
                                                                                1, // Generate a new unique ID by incrementing the max ID
                                                                            from:
                                                                                from.length >
                                                                                0
                                                                                    ? from
                                                                                    : '', // Ensure 'from' is valid
                                                                            to:
                                                                                to.length >
                                                                                0
                                                                                    ? to
                                                                                    : '', // Ensure 'to' is valid
                                                                        },
                                                                    );
                                                                }
                                                            },
                                                        );
                                                    }
                                                }

                                                // Sort newTable by ID in ascending order
                                                // newTable.sort((a, b) => a.id - b.id);

                                                setTableState(newTable); // Update the state with the new table
                                            }}
                                            onChange={(e) => {
                                                e.persist(); // Persist the event for async usage
                                                const inputValue =
                                                    e.target.value.trim();

                                                const newTable = [
                                                    ...getTableState(),
                                                ]; // Create a new copy of the table

                                                const currentRow =
                                                    newTable[index] || {}; // Get the current row being edited

                                                // Handle pasted data or manual entry
                                                const splitEntries = inputValue
                                                    .split(/[\r?\n\s,]+/) // Split by new line, comma, space
                                                    .map((entry) =>
                                                        entry.trim(),
                                                    )
                                                    .filter(Boolean); // Remove empty entries

                                                if (splitEntries.length > 1) {
                                                    // If multiple entries are pasted, handle them
                                                    splitEntries.forEach(
                                                        (entry, idx) => {
                                                            const [from, to] =
                                                                entry.includes(
                                                                    '-',
                                                                )
                                                                    ? entry
                                                                          .split(
                                                                              '-',
                                                                          )
                                                                          .map(
                                                                              (
                                                                                  num,
                                                                              ) =>
                                                                                  num.trim(),
                                                                          )
                                                                    : [
                                                                          entry,
                                                                          entry,
                                                                      ]; // If no hyphen, use the same value for 'from' and 'to'

                                                            if (
                                                                idx === 0 &&
                                                                currentRow.from
                                                                    .length ===
                                                                    0 &&
                                                                currentRow.to
                                                                    .length ===
                                                                    0
                                                            ) {
                                                                // Update the first row if it's empty
                                                                newTable[
                                                                    index
                                                                ] = {
                                                                    id:
                                                                        currentRow.id ||
                                                                        newTable.length +
                                                                            1, // Use existing ID or generate a new one
                                                                    from:
                                                                        from.length >
                                                                        0
                                                                            ? from
                                                                            : '', // Ensure 'from' is valid
                                                                    to:
                                                                        to.length >
                                                                        0
                                                                            ? to
                                                                            : '', // Ensure 'to' is valid
                                                                };
                                                            } else {
                                                                // Generate a unique ID for each additional pasted value
                                                                const maxId =
                                                                    Math.max(
                                                                        0,
                                                                        ...newTable.map(
                                                                            (
                                                                                item,
                                                                            ) =>
                                                                                item.id,
                                                                        ),
                                                                    ); // Get the maximum ID in the table
                                                                newTable.push({
                                                                    id:
                                                                        maxId +
                                                                        1, // Generate a new unique ID
                                                                    from:
                                                                        from.length >
                                                                        0
                                                                            ? from
                                                                            : '', // Ensure 'from' is valid
                                                                    to:
                                                                        to.length >
                                                                        0
                                                                            ? to
                                                                            : '', // Ensure 'to' is valid
                                                                });
                                                            }
                                                        },
                                                    );
                                                } else {
                                                    // If the table has entries and the current row exists
                                                    if (currentRow) {
                                                        // Update the 'from' value of the current row with the new input
                                                        currentRow.from =
                                                            inputValue; // Update the 'from' with the new value

                                                        // Replace the updated row in the newTable
                                                        newTable[index] = {
                                                            ...currentRow,
                                                            id:
                                                                currentRow.id ||
                                                                newTable.length +
                                                                    1, // Ensure ID is preserved or generated
                                                        };
                                                    } else {
                                                        // If the table is empty or the current row doesn't exist, create a new entry
                                                        const maxId = Math.max(
                                                            0,
                                                            ...newTable.map(
                                                                (item) =>
                                                                    item.id,
                                                            ),
                                                        ); // Get the maximum ID in the table
                                                        newTable.push({
                                                            id: maxId + 1, // Generate unique ID for new entries
                                                            from: inputValue, // Use the input value as 'from'
                                                            to: inputValue, // Also set 'to' to the same value
                                                        });
                                                    }
                                                }

                                                setTableState(newTable); // Update the state with the new table
                                            }}
                                            data-walkthroughid={`${numbersPortFrom}/${id}`}
                                        />
                                    </Td>
                                    {/* To */}
                                    <Td>
                                        <Input
                                            name="to"
                                            id="to"
                                            variant="flushed"
                                            placeholder="Please enter to number in E164 format."
                                            className="disabled:cursor-not-allowed"
                                            value={to}
                                            onChange={(e) => {
                                                e.persist();
                                                const newTable = [
                                                    ...modalState.aggregateTable,
                                                ];

                                                newTable[index] = {
                                                    ...newTable[index],
                                                    to: e.target.value,
                                                };

                                                setTableState(newTable);
                                            }}
                                            color={
                                                hasError ? 'red.500' : 'inherit'
                                            }
                                            isInvalid={Boolean(hasError)}
                                            data-walkthroughid={`${numbersPortTo}/${id}`}
                                        />
                                    </Td>
                                    {/* Action Buttons */}
                                    <Td>
                                        <Tooltip
                                            label={!hasError && 'Add row'}
                                            hasArrow>
                                            <IconButton
                                                id={`Add-${index}`}
                                                ref={addButtonRef}
                                                icon={<AddIcon />}
                                                rounded="full"
                                                variant="ghost"
                                                color={
                                                    hasError ? 'red.500' : null
                                                }
                                                isDisabled={hasError}
                                                _hover={{
                                                    bg: darkMode
                                                        ? 'dark.hoverGray'
                                                        : null,
                                                    color: hasError
                                                        ? null
                                                        : 'brand',
                                                }}
                                                _focus={{
                                                    boxShadow: 'none',
                                                }}
                                                onClick={() => {
                                                    handleAddButton();
                                                    handleNextFocus(2, id);
                                                }}
                                                data-walkthroughid={`${numbersPortAddRow}/${id}`}
                                            />
                                        </Tooltip>
                                        <Tooltip label={'Clear row'} hasArrow>
                                            <IconButton
                                                id={`Clear-${index}`}
                                                ref={clearButtonRef}
                                                icon={<CloseIcon />}
                                                rounded="full"
                                                variant="ghost"
                                                _hover={{
                                                    bg: darkMode
                                                        ? 'dark.hoverGray'
                                                        : null,
                                                    color: 'brand',
                                                }}
                                                _focus={{
                                                    boxShadow: 'none',
                                                }}
                                                onClick={() =>
                                                    handleClearButton(index)
                                                }
                                                data-walkthroughid={`${numbersPortClearRow}/${id}`}
                                            />
                                        </Tooltip>
                                    </Td>
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </Box>
        </Box>
    );
};

export default ExistingNumbersTable;
