/* eslint-disable no-unused-vars */
import { CalendarIcon } from '@chakra-ui/icons';
import { FormControl, InputGroup, InputRightElement } from '@chakra-ui/react';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { addDays, isBefore, startOfDay } from 'date-fns';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    ChakraDatePickerDarkModeStyles,
    ChakraDatePickerStyles,
} from '@/chakratheme';

const getDayofMonthBtnProps = (darkMode) => ({
    defaultBtnProps: {
        borderRadius: '50%',
        width: '2rem',
        _hover: {
            background: 'brand.400',
        },
        _active: {
            color: 'brand.500',
            background: 'brand.100',
        },
        color: darkMode ? 'dark.textDark' : 'black',
    },
    selectedBtnProps: {
        background: 'brand.400',
        borderColor: 'blue.300',

        _hover: {
            background: 'brand.300',
        },
    },
});

function MembershipDate({ getValue, row, column, table }) {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState();
    const { darkMode } = useSelector((state) => state.settings);
    const { updateTable } = table.options.meta;
    const getDayofMonthProps = getDayofMonthBtnProps(darkMode);
    const isOwner = row.original?.isOwner;
    const inputProps = {
        height: '32px',
        color: 'black',
        isDisabled: isOwner,
    };
    let initValue = getValue();
    /**
     * @constant @type {import('chakra-dayzed-datepicker/src/utils/commonTypes').PropsConfigs}
     * */
    const propsConfigs = darkMode
        ? {
              ...ChakraDatePickerDarkModeStyles,
              dayOfMonthBtnProps: {
                  ...ChakraDatePickerDarkModeStyles.dayOfMonthBtnProps,
                  ...getDayofMonthProps,
              },
              inputProps,
              popoverCompProps: {
                  popoverContentProps: {
                      background: 'dark.bgDark',
                      boxShadow: 'var(--chakra-shadows-base)',
                  },
              },
              calendarPanelProps: {
                  contentProps: {
                      borderWidth: 0,
                  },
              },
          }
        : {
              ...ChakraDatePickerStyles,
              dayOfMonthBtnProps: {
                  ...ChakraDatePickerStyles.dayOfMonthBtnProps,
                  ...getDayofMonthProps,
              },
              inputProps: {
                  height: '32px',
                  color: 'black',
                  isDisabled: isOwner,
              },
              popoverCompProps: {
                  popoverContentProps: {
                      background: 'white',
                      boxShadow: 'var(--chakra-shadows-base)',
                  },
              },
              calendarPanelProps: {
                  contentProps: {
                      borderWidth: 0,
                  },
              },
          };
    const handleStartDateChange = (value) => {
        const newDate = new Date(value);
        newDate.setHours(0, 0, 0, 0);
        setStartDate(value);
        const date = moment(newDate).format('YYYY-MM-DDTHH:mm:ss.SSSSSSS');
        updateTable(row.index, column.id, date, setStartDate);
    };

    const handleEndDateChange = (value) => {
        const newDate = new Date(value);
        newDate.setHours(0, 0, 0, 0);
        setEndDate(value);
        const date = moment(newDate).format('YYYY-MM-DDTHH:mm:ss.SSSSSSS');
        updateTable(row.index, column.id, date, setEndDate);
    };
    const getStartDate = () => {
        if (isOwner) {
            return undefined;
        }
        if (initValue) {
            return new Date(initValue);
        }
        return startDate;
    };

    const getEndDate = () => {
        if (
            endDate?.toISOString().includes('9999-12-31T23:59:59.999Z') ||
            endDate?.toISOString().includes('1970-01-01T00:00:00.000Z') ||
            isOwner ||
            new Date(row.original?.validFrom).getTime() >
                new Date(row.original?.validTo).getTime()
        ) {
            return undefined;
        }

        if (initValue && !initValue?.includes('9999-12-31')) {
            return new Date(initValue);
        }
        return endDate;
    };
    const generateDisabledDates = (startDate) => {
        const disabledDates = new Set();
        const today = startOfDay(new Date());
        let targetDate = row.original?.validFrom
            ? startOfDay(new Date(row.original.validFrom))
            : startOfDay(startDate);
        let currentDate = today;
        while (isBefore(currentDate, targetDate)) {
            disabledDates.add(currentDate.getTime());
            currentDate = addDays(currentDate, 1);
        }
        return disabledDates;
    };
    const disabledDates = generateDisabledDates(startDate);

    useEffect(() => {
        const isNewRow = !row.original?.validFrom && !row.original?.validTo;
        if (isNewRow) {
            if (column.id === 'validTo') {
                setEndDate(undefined);
            } else if (column.id === 'validFrom') {
                setStartDate(startOfDay(new Date()));
            }
        } else {
            if (column.id === 'validTo') {
                if (
                    initValue?.includes('9999-12-31') ||
                    row.original?.validFrom > row.original?.validTo
                ) {
                    setEndDate(undefined);
                } else {
                    setEndDate(new Date(initValue));
                }
            }
        }
        if (isOwner) {
            setStartDate(undefined);
            setEndDate(undefined);
        }
    }, [
        initValue,
        column.id,
        row.original?.validFrom,
        row.original?.validTo,
        isOwner,
    ]);
    return (
        <FormControl className="my-auto max-w-[630px]">
            <InputGroup>
                {column.id === 'validFrom' ? (
                    <SingleDatepicker
                        name="date-input"
                        date={getStartDate()}
                        usePortal
                        onDateChange={handleStartDateChange}
                        configs={{
                            dateFormat: 'yyyy-MM-dd',
                        }}
                        minDate={startOfDay(new Date())}
                        propsConfigs={propsConfigs}
                    />
                ) : (
                    <SingleDatepicker
                        name="date-input"
                        date={getEndDate()}
                        usePortal
                        onDateChange={handleEndDateChange}
                        configs={{
                            dateFormat: 'yyyy-MM-dd',
                        }}
                        minDate={startOfDay(startDate)}
                        propsConfigs={propsConfigs}
                        disabledDates={disabledDates}
                    />
                )}
                <InputRightElement
                    color={'gray.500'}
                    height="32px"
                    pointerEvents="none">
                    <CalendarIcon />
                </InputRightElement>
            </InputGroup>
        </FormControl>
    );
}
export default MembershipDate;
